<template>
  <div class="wish-list">
    <div class="list" v-if="!isEdit">
      <h4>抽奖结果</h4>
      <a-list item-layout="vertical" size="large" :pagination="pagination" :data-source="tickets">
        <a-list-item slot="renderItem" key="item.id" slot-scope="item">
          <a-divider />
          <p>第 {{item.id}} 个抽奖结果</p>
          <p>抽奖时间： {{item.created_at.slice(0,19)}}</p>
          <p>幸运用户: {{ item.receiver.username }}:{{ item.receiver.first_name}}</p>
          <p>发送礼物用户: {{ item.sender.username }}:{{ item.sender.first_name}}</p>
          <p>地址: {{item.address}}</p>
          <p>快递单号: {{item.tracking_no}}</p>
          <p></p>
        </a-list-item>
      </a-list>
      <a-button @click="clickEdit" type="primary">我也要抽</a-button>
    </div>
    <div class="col-md-12" v-if="isEdit">
      <h4>立即抽奖</h4>
      <a-button @click="handleEdit" type="primary" size="large">抽我!</a-button>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      users: [],
      tickets: [],
      page: 1,
      pagination: {
        onChange: page => {
          this.loadTickets(page);
          this.page = page;
        },
        pageSize: 1000,
      },
      isEdit: false,
    };
  },
  methods: {
    loadAllUsers() {
      this.$http.get(`/api/users`)
        .then(response => {
          this.users = response.data.results;
        })
        .catch(e => {
          console.log(e);
        })
    },
    loadTickets(page) {
      this.$http.get(`/api/tickets?page=${page}`)
        .then(response => {
          this.tickets = response.data.results;
          this.page = page;
        })
        .catch(e => {
          console.log(e);
        })
    },
    clickEdit() {
      this.isEdit = true;
    },
    handleEdit() {
      const body = {}
      this.$http.post(`/api/fetch-ticket/`, body)
        .then(response => {
          console.log(response)
          this.isEdit = false;
          this.loadTickets(1);
        })
        .catch(e => {
          console.log(e)
        });
    },
  },
  mounted() {
    this.loadAllUsers();
    this.loadTickets(1);
  },
}
</script>
